<template>
     <div class="hot-products">
        <div class="hot-title">
            <h4>Hot Products</h4>
            <div class="btns">
                <span class="btn"
                    @click="hotProducts.current = hotProducts.current >= 1 ? hotProducts.current - 1 : 0"
                >
                    <left theme="filled" size="20" fill="#222" />
                </span>
                <span class="btn"
                    @click="hotProducts.current = hotProducts.current < hotProducts.list.length - 3 ? hotProducts.current + 1 : 0"
                >
                    <right theme="filled" size="20" fill="#222" />
                </span>
            </div>
        </div>
        <div class="hot-list"
            :style="`
                width:${hotProducts.list.length * 33.3333}%;
                margin-left:-${hotProducts.current * 33.33333}%;
            `"
        >
            <div class="item" v-for="(item, index) in hotProducts.list" :key="index">
                <div class="box" @click="$router.push(`/equipment/detail/${item.id}`)">
                    <div class="thumb">
                        <img :src="item.thumb" />
                    </div>
                    <div class="text-container">
                        <h5>{{ item.name }}</h5>
                        <div class="ft">
                            <calendar-dot class="icon" theme="filled" size="12" fill="#999" :strokeWidth="3"/>
                            <span class="text">{{ item.published_at }}</span>
                            <eyes class="icon" theme="filled" size="16" fill="#999" :strokeWidth="3"/>
                            <span class="text">{{ item.visits }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CalendarDot, Eyes, Left, Right } from '@icon-park/vue-next'
export default {
    name: 'HotProducts',
    components: {
        CalendarDot, Eyes, Left, Right
    },
    data(){
        return {
            hotProducts: {
                current: 0,
                list: [0,1,2,3,4,5]
            }
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        getData(){
            this.$api.hotEquipments().then(res => {
                console.log(res)
                this.hotProducts.list = res.data;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
// 热门商品
.hot-products{
    @extend .flex-column;
    padding: 60px 0 30px;
    width: 100%;
    overflow: hidden;
    .hot-title{
        @extend .flex-row-between;
        padding: 0 10px;
        h4{
            font-size: 24px;
            font-family: 'cusTitle';
            color: $orange;
        }
        .btns{
            @extend .flex-row;
            .btn{
                @extend .flex-column-center;
                cursor: pointer;
                opacity: 1;
                width: 30px;
                height: 30px;
                background-color: $white;
                box-shadow: $shadow-default;
                .i-icon{
                    height: 20px;
                }
                &:hover{
                    opacity: .5;
                }
            }
        }
    }
    .hot-list{
        @extend .flex-row;
        margin-top: 10px;
        .item{
            width: 410px;
            padding: 0 10px;
            .box{
                @extend .flex-column;
                cursor: pointer;
                border-radius: 10px;
                background-color: $white;
                box-shadow: $shadow-default;
                &:hover{
                    box-shadow: $shadow;
                    .thumb img{
                        width: 120%;
                        margin: -10% 0 0 -10%;
                    }
                    .text-container h5{
                        color: $orange;
                    }
                }
                .thumb{
                    width: 100%;
                    height: 390px;
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                    img{
                        width: 100%;
                    }
                }
                .text-container{
                    padding: 15px;
                    h5{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-family: 'cusTitle';
                        font-size: 18px;
                    }
                    .ft{
                        @extend .flex-row;
                        margin-top: 15px;
                        font-size: 14px;
                        color: #999;
                        .text{
                            margin: 0 10px 0 3px;
                        }
                        .icon{
                            @extend .flex-column-middle;
                        }
                    }
                }
            }
        }
    }
}
</style>