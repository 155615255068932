<template>
    <div class="page-products">
        <div class="product-tit">Products</div>
        <div class="product-cats">
            <div class="item">
                <div class="label">Classification</div>
                <div class="links">
                    <span :class="parentCurrent == 0 ? 'current' : ''"
                        @click="parentCurrent = 0; equipments.children = [];getEquipments();"
                    >All</span>
                    <template v-for="(item, index) in equipments.cats" :key="index">
                        <span 
                            :class="parentCurrent == item.id ? 'current' : ''"
                            @click="$router.push(`/equipment/${item.id}`); parentCurrent = item.id; getChildCat(item.id)"
                        >{{ item.title }}</span>
                    </template>
                </div>
            </div>
            <div class="item" v-if="equipments.children.length > 0 && parentCurrent != 0">
                <div class="label">Secondary</div>
                <div class="links">
                    <span :class="childCurrent === '' ? 'current' : ''"
                        @click="childCurrent = ''; getEquipments($route.params.id)"
                    >All</span>
                    <template v-for="(item, index) in equipments.children" :key="index">
                        <span 
                            :class="childCurrent == item.id ? 'current' : ''"
                            @click="childCurrent = item.id; getEquipmentsByCat(item.id);"
                        >{{ item.title }}</span>
                    </template>
                </div>
            </div>
        </div>
        <div class="product-list">
            <div class="container">
            
                <div class="list">
                    <div class="one" v-for="(item, index) in equipments.list.data" :key="index">
                        <div class="one-container" @click="$router.push(`/equipment/detail/${item.id}`)">
                            <div class="thumb">
                                <img :src="item.thumb" />
                            </div>
                            <div class="text-container">
                                <h5>{{ item.name }}</h5>
                                <div class="attribute">
                                    <p v-if="item.brand">Brand: {{ item.brand }}</p>
                                    <p v-if="item.certification">Certification: {{ item.certification }}</p>
                                    <p v-if="item.origin">Origin: {{ item.origin }}</p>
                                    <p v-if="item.warranty">Warranty: {{ item.warranty }}</p>
                                </div>
                                <div class="bt">
                                    <calendar-dot class="icon" theme="filled" size="16" fill="#999" :strokeWidth="3"/>
                                    <span class="text">{{ (item.published_at).substring(0,10) }}</span>
                                    <eyes class="icon" theme="filled" size="16" fill="#999" :strokeWidth="3"/>
                                    <span class="text">{{ item.visits }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <pagination 
                    v-model:list="equipments.list"
                ></pagination>

                <hot-products />

            </div>
        </div>
    </div>
    <div class="page-bg">
        <img class="bg" src="@/assets/images/product-banner.png" />
    </div>
</template>

<script>
import { CalendarDot, Eyes } from '@icon-park/vue-next'
import HotProducts from '@/components/layout/HotProducts.vue'
import Pagination from '@/components/Pagination.vue'

export default {
    name: 'page-products',
    components: {
        CalendarDot, Eyes,
        HotProducts, Pagination
    },
    data(){
        return {
            equipments: {
                cats: [],
                children: [],
                list: {}
            },
            parentCurrent: '',
            childCurrent: ''
        }
    },
    watch:{
        $route(){
            this.getEquipments(this.$route.params.id, true);
            this.parentCurrent = this.$route.params.id;
        }
    },
    mounted(){
        this.getEquipmentCat();
        if(this.$route.params.id == 2){
            this.parentCurrent = 0;
            this.getEquipments();
        } else {
            this.parentCurrent = this.$route.params.id;
            this.getEquipments(this.$route.params.id, true);
        }
    },
    methods:{
        getEquipmentCat(){
			this.$api.homeEquipmentCat(20).then(res => {
				this.equipments.cats = res.data;
			})
		},
        getChildCat(id){
            this.$api.childrenCat(id).then(res => {
                this.equipments.children = res.data;
                if(res.data.length === 0) this.equipmentsByCat(id);
            })
        },
        getEquipments(id, child){
            this.$api.equipments(id).then(res => {
                this.equipments.list = res.data;
                if(child) this.getChildCat(id);
            })
        },
        getEquipmentsByCat(cat_id){
            this.$api.equipmentsByCat(cat_id).then(res => {
                this.equipments.list = res.data;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-products{
    @extend .flex-column;
    position: relative;
    z-index: 2;
    .product-tit{
        @extend .flex-column-center;
        height: 300px;
        font-family: 'cusTitle';
        font-size: 48px;
        color: $orange;
    }
    .product-cats{
        @extend .flex-column;
        width: 1200px;
        margin: -30px auto 0;
        padding: 30px 30px 0;
        border-radius: $radius-big;
        background-color: $white;
        box-shadow: $shadow;
        .item{
            @extend .flex-row;
            line-height: 24px;
            .label{
                width: 10%;
                text-align: right;
                margin-right: 20px;
                color: $info;
            }
            .links{
                @extend .flex-row;
                width: 90%;
                flex-wrap: wrap;
                span{
                    cursor: pointer;
                    border-radius: $radius;
                    margin: 0 10px 20px;
                    padding: 0 10px;
                    &.current,
                    &:hover{
                        background-color: $orange;
                        color: $white;
                    }
                }
            }
        }
    }
    .product-list{
        width: 100%;
        margin: 30px 0;
        background-color: $page-color;
        .container{
            width: 1220px;
            margin: 0 auto;
            .list{
                @extend .flex-row;
                flex-wrap: wrap;
                margin-bottom: 50px;
                .one{
                    width: 25%;
                    padding: 10px;
                    &-container{
                        cursor: pointer;
                        border-radius: $radius-big;
                        background-color: $white;
                        box-shadow: $shadow-default;
                        .thumb{
                            width: 100%;
                            height: 285px;
                            border-radius: $radius-big $radius-big 0 0;
                            overflow: hidden;
                            img{
                                width: 100%;
                            }
                        }
                        .text-container{
                            padding: 20px;
                            h5{
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: 18px;
                            }
                            .attribute{
                                margin-top: 15px;
                                line-height: 24px;
                                font-size: 14px;
                                p{
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            .bt{
                                @extend .flex-row;
                                margin-top: 20px;
                                font-size: 14px;
                                .text{
                                    margin: 0 10px 0 3px;
                                    color: $info;
                                }
                                .icon{
                                    @extend .flex-column-middle;
                                }
                            }
                        }
                        &:hover{
                            box-shadow: $shadow;
                            .thumb img{
                                    width: 120%;
                                    margin: -10% 0 0 -10%;
                            }
                            .text-container{
                                h5{
                                    color: $orange;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>